
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import StatsService from '@/services/assistant/stats.service.js'
import { Stats } from '@/classes/stats/assistants/stats.js'

export default {
  name: 'chatbotStats',
  components: {
    HeaderTopDashboard,
    StatCard,
  },

  data () {
    return {
      stats: new Stats(),
      cards: [],
      chatbotsPerformanceItems: [],
      search: '',
      tasks: [],
      interacciones: [
        { agente: 'Cyberbot', usuario: 'Noelia Sanchez', fecha: '12/09/2024', hora: '16:20', estado: 'Pendiente' },
        { agente: 'Cyberbot', usuario: 'Maria Quijano', fecha: '12/09/2024', hora: '13:15', estado: 'Abierto' },
        { agente: 'Anacleto', usuario: 'Juan Fernandez', fecha: '09/09/2024', hora: '9:18', estado: 'Cerrado' },
      ],
      lastInteractions: [],
    }
  },
  methods: {

    getStats () {
      StatsService.getStats().then((res) => {
        this.stats.setData(res)
        this.cards = this.stats.getCardsStats()
        console.log(this.cards)
      })
    },

    getChatbotsPerformance () {
      StatsService.getChatbotsPerformance().then((res) => {
        this.chatbotsPerformanceItems = res.map(item => {
          item.closedCalculated = item.total - (item.webChatOpenTotal + item.whatsappOpenTotal)
          item.openCalculated = item.webChatOpenTotal + item.whatsappOpenTotal
          return item
        })
      })
    },
    getTasksData () {
      StatsService.getTasksData().then((res) => {
        this.tasks = res
      })
    },
    getLastInteractions () {
      StatsService.getLastInteractions().then((res) => {
        this.lastInteractions = res
      })
    },
  },
  created () {
  },
  mounted () {
    console.log(Stats)
    this.getStats()
    this.getChatbotsPerformance()
    this.getTasksData()
    this.getLastInteractions()
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('Nombre'),
          value: 'name',
        },
        {
          text: this.$t('Coinv. Total'),
          value: 'total',
        },
        {
          text: this.$t('Mensajes enviados'),
          value: 'totalSent',
        },
        {
          text: this.$t('Mensajes recibidos'),
          value: 'totalContact',
        },
        {
          text: this.$t('Conv. cerradas'),
          value: 'closedCalculated',
        },
        {
          text: this.$t('Conv. pendientes'),
          value: 'openCalculated',
        },
      ]
    },
    taskHeaders () {
      return [
        {
          text: this.$t('Tipo de tarea'),
          value: 'name',
        },
        {
          text: this.$t('Pendientes'),
          value: 'totalPending',
        },
        {
          text: this.$t('Resultas'),
          value: 'totalComplete',
        },
        {
          text: this.$t('Total'),
          value: 'total',
        },
      ]
    },
    interactionHeaders () {
      return [
        {
          text: this.$t('Agente'),
          value: 'name',
        },
        {
          text: this.$t('Usuario'),
          value: 'contact_name',
        },
        {
          text: this.$t('Estado'),
          value: 'is_open',
        },
        {
          text: this.$t('Fecha'),
          value: 'date',
        },
        {
          text: this.$t('Hora'),
          value: 'time',
        },
        {
          text: this.$t('Canal'),
          value: 'channel',
        },
      ]
    },
  },
}
