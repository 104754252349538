
import { Service } from '@/services/service'

const PATH = 'ai/assistant/stats/'

class TaskListService extends Service {
  getStats (params) {
    return this.get(PATH + 'data', params)
  }


  getChatbotsPerformance (params) {
    return this.get(PATH + 'chatbots-performance', params)
  }

  getTasksData (params) {
    return this.get(PATH + 'tasks-data', params)
  }

  getLastInteractions (params) {
    return this.get(PATH + 'last-interactions', params)
  }
}

export default new TaskListService()
